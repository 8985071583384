<template>
  <div>
    <div class="vertical-nav-header d-flex align-center justify-space-between ps-4 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-slide-x-transition>
          <v-img
            :src="appLogoTypography"
            max-height="30px"
            max-width="160px"
            :alt="appName"
          ></v-img>
        </v-slide-x-transition>
      </router-link>

      <v-slide-x-transition>
        <div
          v-show="!(menuIsVerticalNavMini && !isMouseHovered)"
          v-if="$vuetify.breakpoint.lgAndUp"
          class="d-flex align-center ms-1"
          @click.stop="menuIsVerticalNavMini = !menuIsVerticalNavMini"
        >
          <v-icon
            v-show="menuIsVerticalNavMini"
            size="20"
            class="cursor-pointer"
          >
            {{ icons.mdiRadioboxBlank }}
          </v-icon>
        </div>
        <v-icon
          v-else
          size="20"
          class="d-inline-block"
          @click.stop="$emit('close-nav-menu')"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-slide-x-transition>
    </div>

    <vertical-nav-menu-current-club
      class="ps-4 pe-5 pt-1 pb-2"
    />
  </div>
</template>

<script>
import { mdiRadioboxBlank, mdiRecordCircleOutline, mdiClose } from '@mdi/js'
import useAppConfig from '@core/@app-config/useAppConfig'
import themeConfig from '@themeConfig'
import { inject } from '@vue/composition-api'
import VerticalNavMenuCurrentClub from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-current-club/VerticalNavMenuCurrentClub.vue'

export default {
  components: {
    VerticalNavMenuCurrentClub,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()
    const isMouseHovered = inject('isMouseHovered')

    return {
      menuIsVerticalNavMini,
      isMouseHovered,
      appName: themeConfig.app.name,
      appLogoTypography: themeConfig.app.logoTypography,

      // Icons
      icons: {
        mdiClose,
        mdiRadioboxBlank,
        mdiRecordCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}
</style>

<template>
  <v-menu
    offset-y
    left
    nudge-bottom="22"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    content-class="list-style notification-menu-content"
    @input="close"
  >
    <template #activator="{ on, attrs }">
      <v-icon
        :color="color"
        :class="{'shake shake-slow shake-constant': shakeOption}"
        v-bind="attrs"
        v-on="on"
      >
        {{ icons.mdiBellOutline }}
      </v-icon>
    </template>
    <v-card class="app-bar-notification-content-container">
      <perfect-scrollbar
        class="ps-user-notifications"
        :options="perfectScrollbarOptions"
      >
        <v-list class="py-0">
          <!-- Header -->
          <v-list-item
            class="d-flex"
            link
          >
            <div class="d-flex align-center justify-space-between flex-grow-1">
              <span class="font-weight-semibold">通知</span>
              <v-chip
                class="v-chip-light-bg success--text font-weight-semibold"
                small
              >
                {{ notifications.length }}
              </v-chip>
            </div>
          </v-list-item>
          <v-divider></v-divider>

          <!-- Notifications -->
          <v-list-item
            v-for="(notification, notificationIdx) in notifications"
            :key="`${notificationIdx}-${notification.title}`"
            link
          >
            <!-- Content -->
            <v-list-item-content class="d-block">
              <v-list-item-title class="text-sm">
                {{ notification.title }}
              </v-list-item-title>
            </v-list-item-content>

            <!-- Item Action/Time -->
            <v-list-item-action>
              <span class="text--secondary text-xs">{{ notification.time }}</span>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </perfect-scrollbar>
    </v-card>
  </v-menu>
</template>

<script>
import { mdiBellOutline } from '@mdi/js'
import { computed } from '@vue/composition-api'

// 3rd Party
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,
  },
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: '',
    },
  },
  emits: ['update:close-notification-card'],
  setup(props, { emit }) {
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const shakeOption = computed(() => {
      return props.color.length > 0
    })

    const close = () => {
      if (shakeOption.value) emit('update:close-notification-card')
    }

    return {
      close,
      perfectScrollbarOptions,
      shakeOption,
      icons: {
        mdiBellOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
@import '~csshake/scss/csshake-slow.scss';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>

<template>
  <div>
    <v-select
      v-if="availableClubs.length > 1"
      :value="currentClub.id"
      :items="availableClubs"
      item-value="id"
      item-text="name"
      :loading="isLoading"
      :menu-props="{ auto: true, offsetY: true }"
      dense
      hide-details
      class="pa-0 ma-0 theme--dark"
      @input="updateCurrentClub"
    />

    <v-text-field
      v-else
      :value="currentClub.name"
      dense
      hide-details
      readonly
      class="pa-0 ma-0 theme--dark"
    />
  </div>
</template>

<script>
import { ref, getCurrentInstance } from '@vue/composition-api'
import { useRouter } from '@core/utils'

import camelcaseKeys from 'camelcase-keys'

import UserApi from '@/api/waiter/User'
import useCurrentData from '@/views/composable/useCurrentData'
import useCompInit from '@/views/composable/useCompInit'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { initWith, isLoading } = useCompInit()
    const { currentClub } = useCurrentData()
    const { router } = useRouter()
    const availableClubs = ref([])

    const getUsersOwnerships = async () => {
      const res = await UserApi.getUsersOwnerships()

      if (res?.data) {
        availableClubs.value = res.data
      }
    }

    const updateCurrentClub = async id => {
      if (+id === +currentClub.value.id) return

      const res = await UserApi.updateUserCurrentClubId({ clubId: id })
      if (res?.data) {
        localStorage.setItem('currentClub', JSON.stringify(camelcaseKeys(res.data)))

        router.replace({
          params: {
            club_slug: res.data.slug,
          },
        })
        router.go('/')

        vm.$toast.success('店舗切り替えが成功しました')
      }
    }

    initWith([
      getUsersOwnerships(),
    ])

    return {
      // data
      isLoading,
      availableClubs,

      // methods
      updateCurrentClub,
    }
  },
}
</script>

import { watch } from '@vue/composition-api'
import { useWindowSize } from '@vueuse/core'

export default () => {
  const setVh = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
  }
  const {
    width: windowWidth,
    height: windowHeight,
  } = useWindowSize()

  watch(
    [windowWidth, windowHeight],
    setVh,
    {
      immediate: true,
      deep: true,
    },
  )

  return {}
}
